import { Grid, Link, Spacer } from "@geist-ui/core"
import { Cube, FolderSimple, GridFour, Leaf, Play, Target } from 'phosphor-react'

import { StatisticCard } from '../client/components'
import { EmptyState } from '../client/ui'
import { useCurrentTeam } from '../client/data/useTeam'
import EventTimeline from '../client/components/timeline'
import SectionCard from '../client/components/section-card'

import { useEvents } from '../client/data'

import { TaskListItem } from '../client/components/task'
import { TaskModal } from '../client/components/task'
import { useUser } from '../client/utils/useUser'
import { Calendar, Smile } from '@geist-ui/icons'

import { useSWRDataWithAuth } from '../client/data/useSWRData'
import Network from '../client/services/network'
import Harvest from "../client/core/harvest/model"
import { SimpleBarChart } from "../client/ui"

const EventSection = ({ setModal }) => {
  const { events } = useEvents({ limit: 8 })
  return <SectionCard action={{ text: 'View All', href: '/events' }} title="Recent Events (All Spaces)" icon={<Calendar size={20} />}>
    <EventTimeline setModal={setModal} events={events} />
  </SectionCard>
}


const TaskSectionFooter = () => {

  return <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: "20px" }}>
    <Link color href='/tasks'>View All</Link>
  </div>
}

const TaskSection = ({ setModal }) => {
  const { user } = useUser()
  const { refreshEvents } = useEvents({ limit: 8 })
  const { tasks, refreshTasks } = useSWRDataWithAuth('/api/v1/tasks', {
    filter: {
      dueDate: {
        operation: "or",
        value: [{
          operation: "gt",
          value: new Date().setHours(0, 0, 0, 0)
        }, {
          operation: "lt",
          value: new Date().setHours(23, 59, 59, 999)
        }]
      },
      status: {
        operation: "eq",
        value: "created"
      }
    }
  }, { name: "tasks" })

  const completeTasks = async () => {
    await Network.post(`/api/v1/tasks/complete`, user?.accessToken, {
      tasks: tasks.map(t => t._id)
    })
    await refreshTasks()
    await refreshEvents()
    setModal()
  }

  if (!tasks) return <SectionCard icon={<Target size={20}></Target>} title='Your Tasks'>
    <div style={{ padding: "10px" }}>
      {[1, 2, 4].map(task => <TaskListItem key={task} loading></TaskListItem>)}
    </div>
  </SectionCard>

  return <SectionCard action={tasks?.length ? {
    text: <Grid.Container alignItems='center'><Play size={20}></Play> <Spacer w={0.5} /> Perform as Sequence</Grid.Container>,
    onClick: () => setModal(<TaskModal callback={completeTasks} tasks={tasks}></TaskModal>)
  } : null} footer={<TaskSectionFooter></TaskSectionFooter>} icon={<Target size={20}></Target>} title={"Today's Tasks (" + tasks.length + ")"}>
    <div style={{ padding: "10px" }}>
      <Spacer></Spacer>
      {tasks?.length > 0 ?
        tasks?.map(task => <div key={task._id}>
          <TaskListItem setModal={setModal} onComplete={refreshTasks} task={task} />
        </div>) : <EmptyState title='No Tasks due today!' subtitle='Head outside and chill.' icon={<Smile size={40}></Smile>}>
        </EmptyState>}
    </div>
  </SectionCard>
}



const HarvestSection = ({ setModal }) => {
  const { user } = useUser()
  const { harvests } = useSWRDataWithAuth(Harvest.baseURL, {
    sort: { created: -1 },
    filter: {},
    limit: 5
  }, { name: "harvests", model: Harvest, v2: true })

  if (!harvests) return <SectionCard >Loading</SectionCard>
  const formattedData = harvests.map(h => ({ name: h.name, value: h.yield / 1000, unit: "kg", ...h }))

  return <SectionCard
    action={{ text: "View All", href: "/harvests" }}
    icon={<Cube weight="fill" size={20} />}
    title={"Recent Harvests (Yield Size)"}>
    <div style={{ padding: "10px", width: "100%", minHeight: "300px" }}>
      <SimpleBarChart data={formattedData}></SimpleBarChart>
    </div>
  </SectionCard>
}


export default function Home({ setModal }) {

  const { team, teamLoading } = useCurrentTeam()
  const { data } = useSWRDataWithAuth('/api/v1/teams/' + team?._id + '/stats')

  return <>
    <Grid.Container gap={2}>
      <Grid sm={14} xl={16} xs={24} direction='column' >
        <Grid.Container gap={2}>
          <StatisticCard Icon={Leaf} loading={teamLoading} title="Active Plants" amount={`${data?.plants ?? "--"}`} />
          <StatisticCard Icon={GridFour} loading={teamLoading} title="Spaces" name="GridFour" amount={`${data?.spaces ?? "--"}`} />
          <StatisticCard Icon={FolderSimple} loading={teamLoading} title="Active Batches" name="Folder" amount={`${data?.batches ?? "--"}`} />
        </Grid.Container>
        <Spacer h={2}></Spacer>
        <HarvestSection setModal={setModal} />
        <TaskSection setModal={setModal}></TaskSection>
      </Grid>
      <Grid direction='column' xs={24} xl={8} sm={10}>
        <EventSection setModal={setModal} />
      </Grid>
    </Grid.Container></>
}
